/** @format */

import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { showNotification } from "@mantine/notifications";
import { AiOutlineSearch } from "react-icons/ai";

import {
  ActionIcon,
  Badge,
  Button,
  Input,
  Pagination,
  Select,
  Skeleton,
  Table,
  Text,
  CloseButton,
  TextInput,
  Anchor,
  Modal,
} from "@mantine/core";
import { BeatLoader } from "react-spinners";
import { DatePicker } from "@mantine/dates";
import { Eye, Plus, Search } from "tabler-icons-react";
import { formatISO } from "date-fns";
import { useDebouncedValue } from "@mantine/hooks";
import { deleteAgreementBulk } from "../../utilities/apis/agreements";
import {
  getValueForInput,
  loadingStates,
  csvDownload,
  formatDate,
} from "../../utilities/utilities";
import { apiWrapWithErrorWithData } from "../../utilities/apiHelpers";
import {
  getAgreementsList,
  getAgreementTypesApi,
  getAgreementsCsvDownload,
} from "../../utilities/apis/agreements";
import UserAvatarView from "../../components/UserAvatarView";
import DashboardNotifications from "../DashboardNotifications";
import { agreementStatusColors } from "../../utilities/enums";
import AgreementAnalytics from "../../components/AgreementAnalytics/AgreementAnalytics";
import colors, { themeColor } from "../../utilities/design";
import AgreementGraph from "./AgreementGraph";
import Styles from "./AgreementsListingPage.module.css";
import { INDIA_STATES_UNIONS } from "./AgreementForm2";
import HamburgerMenu from "../HamburgerMenuAgreements";
import HamburgerMenuAgreements from "../HamburgerMenuAgreements";
import AgreementAnalytics1 from "../../components/AgreementAnalytics/AgreementAnalytics1";
import AgreementGraph1 from "./AgreementGraph1";
import { getAgreementsInSignedList } from "../../utilities/apis/agreements";
import { useSelector } from "react-redux";
import { selectUserData } from "../../redux/selectors";
import { getBussinessUnit } from "../../utilities/apis/serviceProvider";

const statusOptions = [
  {
    label: "Pending with Company",
    value: "Pending with Company",
  },
  {
    label: "TP",
    value: "TP",
  },
];

const estampOption = [
  {
    label: "Yes",
    value: "Yes",
  },
  {
    label: "No",
    value: "No",
  },
];

const AgreementsSigned = () => {
  const [businessunitList, setbusinessunitList] = useState([]);
  useEffect(() => {
    const fetchBusinessUnits = async () => {
      try {
        const { data } = await getBussinessUnit();
        if (data) {
          setbusinessunitList(data.businessunitList || []);
        } else {
          console.error("Invalid business unit response format:", response);
        }
      } catch (error) {
        console.error("Error fetching business units:", error);
      }
    };

    fetchBusinessUnits();
  }, []);
  const [agreementTypeOptions, setAgreementTypeOptions] = useState([]);
  const [transformedData, settransformedData] = useState([]);
  const getAgreementTypes = async () => {
    const resp = await apiWrapWithErrorWithData(getAgreementTypesApi());
    if (resp?.success && resp?.typesOfAgreement) {
      const uniqueValues = Array.from(new Set([...resp.typesOfAgreement]));
      const temp = uniqueValues.map((item) => ({ label: item, value: item }));
      setAgreementTypeOptions([...agreementTypeOptions, ...temp]);
    }
  };
  useEffect(() => {
    getAgreementTypes();
  }, []);
  useEffect(() => {
    if (businessunitList.length > 0) {
      settransformedData(
        businessunitList.map((unit) => ({
          value: unit.id + "",
          label: unit.name,
        }))
      );
    }
  }, [businessunitList]);

  const navigate = useNavigate();
  const onViewClick = (agreementId) => {
    // navigate(`/app/agreements/details/${agreementId}`);
    const url =
      process.env.PUBLIC_URL +
      `/app/agreements_manager/agreements/details/${agreementId}`;
    window.open(url, "_blank", "noopener,noreferrer");
  };

  const [year, setYear] = useState(2024);
  const [filters, setFilter] = useState({
    typeOfAgreement: null,
    mapBusinessunit: null,
    startDate: null,
    endDate: null,
    estamp: null,
    partyName: null,
  });
  const clearHandler = () => {
    setFilter({
      typeOfAgreement: null,
      mapBusinessunit: null,
      startDate: null,
      endDate: null,
      estamp: null,
      partyName: null,
    });
    setReload(!reload);
  };
  const [pageSize, setPageSize] = useState(10);
  const [reload, setReload] = useState(true);
  const [reviewAgreementpage, setReviewAgreementpage] = useState(1);
  let serialNumber = (reviewAgreementpage - 1) * pageSize + 1;
  const [configs, setConfigs] = useState({
    loading: loadingStates.LOADING,
    data: null,
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        let filterOptions = {};
        if (filters.startDate && filters.endDate) {
          filterOptions["createdAt"] = {
            gte: formatISO(filters.startDate),
            lte: formatISO(filters.endDate),
          };
        } else if (filters.startDate || filters.endDate) {
          // Notify if only one date is selected
          showNotification({
            title: "Agreement notification",
            message: "Please select both start and end dates",
            color: "red",
          });
          return; // Exit if only one date is selected
        }
        if (
          filters.typeOfAgreement &&
          filters.estamp &&
          filters.mapBusinessunit
        ) {
          filterOptions = {
            ...filterOptions,
            typeOfAgreement: { equals: filters.typeOfAgreement },
            agreementType: {
              equals: filters.estamp === "Yes" ? "eStamp" : "normal",
            },
            mapBusinessunit: { equals: filters.mapBusinessunit },
          };
        } else {
          // Individual filter checks if any one of these fields is present
          if (filters.typeOfAgreement) {
            filterOptions["typeOfAgreement"] = {
              equals: filters.typeOfAgreement,
            };
          }
          if (filters.estamp) {
            filterOptions["agreementType"] = {
              equals: filters.estamp === "Yes" ? "eStamp" : "normal",
            };
          }
          if (filters.mapBusinessunit) {
            filterOptions["mapBusinessunit"] = {
              equals: filters.mapBusinessunit,
            };
          }
        }

        if (filters.partyName) {
          filterOptions["OR"] = [
            {
              secondParty: {
                path: "$.name",
                string_contains: filters.partyName,
                // mode: "insensitive",
              },
            },
            {
              secondParty: {
                path: "$.indi_name",
                string_contains: filters.partyName,
                // mode: "insensitive",
              },
            },
          ];
        }

        const response = await apiWrapWithErrorWithData(
          getAgreementsInSignedList({
            page: reviewAgreementpage,
            pageSize: pageSize,
            filterOptions: filterOptions,
            year: year,
          })
        );
        if (response) {
          setConfigs({
            loading: loadingStates.NO_ACTIVE_REQUEST,
            data: response,
          });
        }
      } catch (error) {
        console.error("Error fetching data: ", error);
      }
    };
    fetchData();
  }, [reviewAgreementpage, pageSize, year, reload]);

  return (
    <>
      {configs.loading === loadingStates.NO_ACTIVE_REQUEST && (
        <>
          <div
            className="flex"
            style={{
              justifyContent: "space-between",
              alignItems: "center",
              alignItems: "center",
              marginBottom: "12px",
            }}
          >
            <h1
              style={{
                fontSize: "22px",
                fontWeight: "600",
                letterSpacing: "1px",
                textTransform: "lowercase",
                fontFamily: "Lexend",
                whiteSpace: "nowrap",
                paddingBottom: "13px",
                position: "relative",
                color: "black",
                // margin: "0 auto"
              }}
            >
              agreements signed
            </h1>

            <HamburgerMenuAgreements />
          </div>
          <div className="flex flex-row flex-wrap w-full">
            <AgreementAnalytics1
              filter={filters}
              setFilter={setFilter}
              configs={configs}
            />
          </div>
          <div className="flex">
            <AgreementGraph year={year} setYear={setYear} configs={configs} />
            <AgreementGraph1 configs={configs} />
          </div>

          <div className="flex flex-col">
            <div className="flex justify-between my-4">
              <Text>table view</Text>
            </div>
          </div>
        </>
      )}

      {configs.loading === loadingStates.LOADING && (
        <div className="flex flex-col">
          {Array.from({ length: 10 }).map((i) => (
            <Skeleton key={i} height={30} radius="md" className="my-1 w-full" />
          ))}
          <div className="flex flex-row justify-center">
            <Skeleton height={40} radius="md" className="w-40" />
          </div>
        </div>
      )}
      <div className="flex w-full justify-between items-center">
        <div className="flex flex-col">
          <div className="flex flex-row-sm">
            {/* <Text size="xs" className="mb-2" color="gray">
              Case sensitive search
            </Text> */}
            <div className="flex items-center">
              {/* <TextInput
                value={listing.searchString}
                onChange={(input) => {
                  const val = getValueForInput(input);
                  setListing((prevState) => ({
                    ...prevState,
                    searchString: val,
                  }));
                }}
                placeholder="Type to search..."
                icon={<Search size={14} />}
              /> */}

              <Select
                className="mx-2"
                placeholder="estamp"
                value={filters.estamp}
                onChange={(val) => {
                  setFilter((prevState) => ({
                    ...prevState,
                    estamp: val,
                  }));
                }}
                data={estampOption}
                style={{ minWidth: "228px" }}
              />
              <Select
                className="mx-2"
                placeholder="select agreement type"
                value={filters.typeOfAgreement}
                onChange={(val) => {
                  setFilter((prevState) => ({
                    ...prevState,
                    typeOfAgreement: val,
                  }));
                }}
                data={agreementTypeOptions}
                style={{ minWidth: "228px" }}
              />
              <Select
                className="mx-2"
                placeholder="select business unit"
                value={filters.mapBusinessunit}
                data={transformedData}
                onChange={(value) => {
                  setFilter((prevState) => ({
                    ...prevState,
                    mapBusinessunit: value,
                  }));
                }}
                style={{ minWidth: "228px" }}
              />
            </div>
          </div>
        </div>
      </div>
      <br />
      <div className="flex w-full justify-between items-center">
        <div className="flex flex-col">
          <div className="flex flex-row">
            <div className="flex items-center">
              {/* <Select
                className="mx-2"
                placeholder="Select location"
                searchable={true}
                value={listing.location}
                onChange={(val) => {
                  setListing((prevState) => ({
                    ...prevState,
                    location: val,
                  }));
                }}
                data={INDIA_STATES_UNIONS}
                style={{ minWidth: "228px" }}
              /> */}
              <DatePicker
                className="mx-2"
                value={filters.startDate}
                onChange={(val) =>
                  setFilter((preState) => ({ ...preState, startDate: val }))
                }
                placeholder="created date (from)"
                minDate={
                  new Date(new Date().setMonth(new Date().getMonth() - 3))
                }
                maw={400}
                mx="auto"
                style={{ minWidth: "228px" }}
              />
              <DatePicker
                className="mx-2"
                value={filters.endDate}
                onChange={(val) =>
                  setFilter((preState) => ({ ...preState, endDate: val }))
                }
                placeholder="created date (to)"
                minDate={filters.startDate}
                disabled={filters.startDate ? false : true}
                style={{ minWidth: "228px" }}
              />
              <TextInput
                className="mx-2"
                value={filters.partyName}
                onChange={(input) => {
                  const val = getValueForInput(input);
                  setFilter((prevState) => ({
                    ...prevState,
                    partyName: val,
                  }));
                }}
                placeholder="party name"
                type="text"
                style={{ minWidth: "228px" }}
              />

              <br />
            </div>
          </div>
        </div>
      </div>
      <br />
      <Button className="mx-2" color="green" onClick={() => setReload(!reload)}>
        search
      </Button>
      <Button className="mx-2" color="red" onClick={clearHandler}>
        clear
      </Button>

      <Button
        className="mx-2 btn btn-success btn-lg float-right"
        // disabled={exportButtonLoading}
        // onClick={handleCsvDownload}
        // color={themeColor(colors.agreement)}
        style={{
          backgroundColor :"#46BDE1"
        }}
        
      >
        export
      </Button>
      {configs.loading === loadingStates.NO_ACTIVE_REQUEST &&
        configs.data?.agreements && (
          <div className="flex flex-col">
            <Table striped>
              <thead>
                <tr>
                  <th>sr no.</th>
                  <th>ain</th>
                  <th>businesss unit</th>
                  <th>type</th>
                  <th>second party</th>
                  <th>start date</th>
                  <th>end date</th>
                  <th>&nbsp;</th>
                  <th>&nbsp;</th>
                </tr>
              </thead>
              <tbody>
                {configs.data.agreements.map((row) => (
                  <tr key={row.id}>
                    <td>{serialNumber++}</td>
                    <td>{`agreement-${row.id}`}</td>
                    <td>{row.agreementData.json.location}</td>
                    <td>{row.agreementData.json.typeOfAgreement}</td>
                    <td>
                      {/* <UserAvatarView {...row.createdBy} /> */}
                      {row.agreementData.json.secondParty.name || "NA"}
                    </td>
                    <td>{row.contractStartDate?.slice(0, 10) || "-"}</td>
                    <td> {row.contractEndDate?.slice(0, 10)}</td>
                    <td style={{ textAlign: "center" }}>
                      {row.relationshipWith ? (
                        <div className="flex flex-row ">
                          <ActionIcon
                            onClick={() => {
                              handlePlusClick(row.relationshipWith);
                            }}
                            color="white"
                          >
                            <Plus color="#46BDE1"  size={24} />
                          </ActionIcon>
                          {/* )} */}
                        </div>
                      ) : (
                        <span className="mr-2">{"--"}</span>
                      )}
                    </td>
                    <td>
                      <div className="flex flex-row mr-4">
                        <ActionIcon
                          onClick={() => {
                            onViewClick(row.id);
                          }}
                          color="white"
                        >
                          <Eye color="#46BDE1"  size={24} />
                        </ActionIcon>
                        {/* )} */}
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        )}
      {configs.loading === loadingStates.NO_ACTIVE_REQUEST &&
      !configs.data.agreements?.length ? (
        <div
          className="flex justify-center items-center"
          style={{
            minHeight: "300px",
          }}
        >
          No agreements
        </div>
      ) : null}
      {/* </div> */}
      {configs.loading === loadingStates.NO_ACTIVE_REQUEST &&
      configs.data?.agreementsCount ? (
        <div className="flex flex-row justify-center my-4">
          <Pagination
            page={reviewAgreementpage}
            onChange={(page) => {
              setReviewAgreementpage(page);
            }}
            total={Math.ceil(configs.data?.agreementsCount / pageSize)}
          />
          <Text className="mx-4">select page size</Text>
          <Select
            className="w-20"
            data={["10", "20", "50"]}
            value={pageSize + ""}
            onChange={(val) => setPageSize(parseInt(val))}
          />
        </div>
      ) : null}
    </>
  );
};

export default AgreementsSigned;
