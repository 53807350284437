import React, { useEffect, useState } from "react";
import { Badge, Button, Modal } from "@mantine/core";
import { showNotification } from "@mantine/notifications";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { BeatLoader } from "react-spinners";
import { useSelector } from "react-redux";
import styles from "./AgreementDetail.module.css";
import ContractDetail from "./ContractDetail";
import PartyDetail from "./PartyDetail";
import { formatDate, formatTime } from "../../utilities/utilities";
import {
  agreementStatusColors,
  getStatusColor,
  getStatusText,
} from "../../utilities/enums";
import {
  deleteAgreement,
  deleteAgreementBulk,
  getAgreementDetails,
} from "../../utilities/apis/agreements";
import { apiWrapWithErrorWithData } from "../../utilities/apiHelpers";
import colors from "../../utilities/design";
import { selectUserData } from "../../redux/selectors";
import UserSelector from "../UserSelector/UserSelector";
import { getUserRolesUsingRoleId } from "../../utilities/apis/serviceProvider";
import EditSignatoryDetails from "./EditSignatoryDetail";

function AgreementDetail({ agreementData }) {
  const navigate = useNavigate();
  console.log(agreementData);
  const userDetails = useSelector(selectUserData);
  const currentUser = useSelector(selectUserData) || {};
  const agreementId = agreementData?.agreementId;
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [open, setOpen] = useState(false);

  const [userRolesIdData, setUserRolesIdData] = useState({
    userRoles: {
      Initiator: [],
      Editor: [],
      Reviewer: [],
      Approver: [],
      SigningAuthority: [],
    },
  });
  const flowJson = agreementData.wholeAgreement.flowJson;

  const checkUserCompletionInFlow = (key, userId) => {
    if (flowJson[key]) {
      const userEntry = flowJson[key].find((entry) => entry.userId === userId);
      if (userEntry) {
        return userEntry.isComplete;
      }
    }
    return false;
  };

  useEffect(() => {
    const fetchUserRoles = async () => {
      try {
        const roles = {
          Initiator: (await getUserRolesUsingRoleId(3)).data.userList,
          Editor: (await getUserRolesUsingRoleId(4)).data.userList,
          Reviewer: (await getUserRolesUsingRoleId(5)).data.userList,
          Approver: (await getUserRolesUsingRoleId(6)).data.userList,
          SigningAuthority: (await getUserRolesUsingRoleId(7)).data.userList,
        };

        setUserRolesIdData((prevData) => ({
          ...prevData,
          userRoles: roles,
        }));
      } catch (error) {
        console.error("Error fetching user roles:", error);
      }
    };

    fetchUserRoles();
  }, [agreementId]);
  const [approvers, setApprovers] = useState({});
  const [reviewers, setReviewer] = useState({});
  const [editors, setEditor] = useState({});
  const [initiators, setInitiator] = useState({});
  const [signAuths, setSignAuth] = useState({});
  const [showEditSignatoryModal, setShowEditSignatoryModal] = useState(false);

  useEffect(() => {
    const fetchUserRoles = () => {
      if (
        agreementData &&
        userRolesIdData &&
        !agreementData.data.json.isLegacy
      ) {
        const newReviewers = {};
        if (agreementData.wholeAgreement.flowJson["5"]?.length > 0) {
          agreementData.wholeAgreement.flowJson["5"]?.forEach((item) => {
            const reviewer = userRolesIdData.userRoles.Reviewer.find(
              (role) => role.id === item.userId
            );
            if (reviewer) {
              newReviewers[item.userId] = reviewer;
            }
          });
          setReviewer((prevReviewers) => ({
            ...prevReviewers,
            ...newReviewers,
          }));
        }
        const newApprovers = {};
        if (agreementData.wholeAgreement.flowJson["6"]?.length > 0) {
          agreementData.wholeAgreement.flowJson["6"]?.forEach((item) => {
            const approver = userRolesIdData.userRoles.Approver.find(
              (role) => role.id === item.userId
            );
            if (approver) {
              newApprovers[item.userId] = approver;
            }
          });
          setApprovers((prevApprovers) => ({
            ...prevApprovers,
            ...newApprovers,
          }));
        }

        const newEditors = {};
        if (agreementData.wholeAgreement.flowJson["4"]?.length > 0) {
          agreementData.wholeAgreement.flowJson["4"]?.forEach((item) => {
            const editor = userRolesIdData.userRoles.Editor.find(
              (role) => role.id === item.userId
            );
            if (editor) {
              newEditors[item.userId] = editor;
            }
          });
          setEditor((prevEditor) => ({ ...prevEditor, ...newEditors }));
        }
        const newInitiators = {};
        if (agreementData.wholeAgreement.flowJson["3"]?.length > 0) {
          agreementData.wholeAgreement.flowJson["3"]?.forEach((item) => {
            const initiator = userRolesIdData.userRoles.Initiator.find(
              (role) => role.id === item.userId
            );
            if (initiator) {
              newInitiators[item.userId] = initiator;
            }
          });
          setInitiator((prevInitiator) => ({
            ...prevInitiator,
            ...newInitiators,
          }));
        }

        const newSignAuths = {};
        if (agreementData.wholeAgreement.flowJson["7"]?.length > 0) {
          agreementData.wholeAgreement.flowJson["7"]?.forEach((item) => {
            const signAuth = userRolesIdData.userRoles.SigningAuthority.find(
              (role) => role.id === item.userId
            );
            if (signAuth) {
              newSignAuths[item.userId] = signAuth;
            }
          });
          setSignAuth((prevSignAuths) => ({
            ...prevSignAuths,
            ...newSignAuths,
          }));
        }
      }
    };
    fetchUserRoles();
  }, [agreementData, userRolesIdData]);

  // Ensure that agreementData and userRolesIdData are dependencies in useEffect

  // console.log("Approvers : ", approvers);

  const deleteHandler = async () => {
    setDeleteLoading(true);
    const response = await apiWrapWithErrorWithData(
      deleteAgreement({ agreementId })
    );
    if (response?.success) {
      showNotification({
        color: "green",
        title: "Agreements",
        message: "Agreement deleted successfully.",
      });
      setOpen(false);
      navigate("/app/agreements_manager");
    } else {
      showNotification({
        color: "red",
        title: "Agreements",
        message: "Unable to delete agreement",
      });
    }
    setDeleteLoading(false);
  };
  // console.log(agreementData);
  console.log("editors :", editors);

  return (
    <div className="flex flex-col pt-7 pl-4 pr-7">
      <div className={`${styles.title} flex flex-row justify-between my-2`}>
        <div>{agreementData.wholeAgreement.titleOfAgreement}</div>
        <div>
          <div className="flex flex-row justify-between">
            {(currentUser.loginAs.id === 1 ||
              currentUser.loginAs.id === 2 ||
              currentUser.loginAs.id === 4) && (
              <Button
                disabled={
                  agreementData.agreementStatus === "SIGNED" ? true : false
                }
                className="ml-4"
                style={{
                  backgroundColor: "#46BDE1",
                  borderRadius: "0.5rem",
                  color: "#F5F5F5",
                  // width: '180px',
                }}
                onClick={() => {
                  navigate("/app/agreements_manager/agreements/edit", {
                    state: {
                      // agreementId: agreementData.agreementId,
                      agreementId,
                    },
                  });
                }}
              >
                edit
              </Button>
            )}
            {/* {(currentUser.loginAs.id === 1 ||
              currentUser.loginAs.id === 2 ||
              currentUser.loginAs.id === 4)  && !agreementData.data.flatted.isLegacy && (
                <Button
                  disabled={
                    agreementData.agreementStatus === "SIGNED" ? true : false
                  }
                  className="ml-4"
                  style={{
                    backgroundColor: "#46BDE1",
                    borderRadius: "0.5rem",
                    color: "#F5F5F5",
                    // width: '180px',
                  }}
                  onClick={() =>
                    setShowEditSignatoryModal(true)
                  }
                >
                  Edit
                </Button>
              )} */}
            {(currentUser.loginAs.id === 1 ||
              currentUser.loginAs.id === 2 ||
              currentUser.loginAs.id === 4) &&
              agreementData?.actionText !== "sent_to_doc_locker" && (
                <Button
                  className="ml-4"
                  style={{
                    backgroundColor: "#ff4d4d",
                    borderRadius: "0.5rem",
                    color: "#F5F5F5",
                    // width: '180px',
                  }}
                  onClick={() => setOpen(true)}
                >
                  delete
                </Button>
              )}
          </div>
        </div>
      </div>
      <Modal
        opened={showEditSignatoryModal}
        onClose={() => setShowEditSignatoryModal(false)}
      >
        <EditSignatoryDetails
          agreementData={agreementData}
          setShowEditSignatoryModal={setShowEditSignatoryModal}
        />
      </Modal>
      <div className="flex flex-row justify-between items-center mt-2">
        <div>
          <h1>{`agreement ${agreementData.agreementId}`}</h1>
          <div className="flex flex-row">
            <div className="flex flex-row mr-4">
              <img
                className="pr-2"
                src="/assets/images/calendar.svg"
                alt="calendar"
              />
              <span className={styles.dateTime}>
                created on {formatDate(agreementData.wholeAgreement.createdAt)}
              </span>
            </div>
            <div className="flex flex-row mr-4">
              <img
                className="pr-2"
                src="/assets/images/clock.svg"
                alt="clock"
              />
              <span className={styles.dateTime}>
                {" "}
                {formatTime(agreementData.wholeAgreement.createdAt)}
              </span>
            </div>
          </div>
        </div>
        <div>
          {agreementData.wholeAgreement.typeOfAgreement === "eStamp" ? (
            <Badge
              style={{
                borderRadius: "16px",
                // color: '#121212',
                padding: "3px 8px",
                width: "170px",
                height: "33px",
                color: "bule",
                marginRight: "5px",
              }}
              color={[agreementData.wholeAgreement.typeOfAgreement] || "blue"}
            >
              {agreementData.wholeAgreement.typeOfAgreement}
            </Badge>
          ) : null}
          <Badge
            style={{
              borderRadius: "16px",
              // color: '#121212',
              padding: "3px 8px",
              width: "170px",
              height: "33px",
            }}
            color={getStatusColor(agreementData.agreementStatus) || "orange"}
          >
            {getStatusText(agreementData.agreementStatus)}
          </Badge>
          {agreementData.agreementStatus !== "COMPLETED" &&
          !agreementData.data.json.isLegacy ? (
            <div className="mt-2 justify-center ml-4">
              {`pending since ${agreementData.noOfCompletionDays} days`}
            </div>
          ) : null}
        </div>
      </div>
      <ContractDetail
        typeOfAgreement={agreementData.wholeAgreement.typeOfAgreement}
        termOfContractInDays={agreementData.wholeAgreement.contractTermInDays}
        dateOfContract={agreementData.wholeAgreement.dateOfContract}
        // endDate={contractEndDate}
        endDate={agreementData.wholeAgreement.dueDateOfContract}
        location={agreementData.wholeAgreement.mapBusinessunit}
      />
      <div>
        <div className={styles.title}>first party</div>
        <PartyDetail
          party={agreementData.wholeAgreement.firstParty}
          agreementData={agreementData}
        />
        {/* <AddressRenderer {...firstParty} /> */}
      </div>
      <div>
        <div className={styles.title}>second party</div>
        {/* <AddressRenderer {...secondParty} /> */}
        <PartyDetail
          party={agreementData.wholeAgreement.secondParty}
          agreementData={agreementData}
        />
      </div>
      {agreementData.wholeAgreement.twentythParty && (agreementData.wholeAgreement.thirdParty.indi_name ||
        agreementData.wholeAgreement.thirdParty.name) && (
        <div>
          <div className={styles.title}>third party</div>
          {/* <AddressRenderer {...secondParty} /> */}
          <PartyDetail
            party={agreementData.wholeAgreement.thirdParty}
            agreementData={agreementData}
          />
        </div>
      )}
      {agreementData.wholeAgreement.twentythParty && (agreementData.wholeAgreement.fourthParty.indi_name ||
        agreementData.wholeAgreement.fourthParty.name) && (
          <div>
            <div className={styles.title}>fourth party</div>
            {/* <AddressRenderer {...secondParty} /> */}
            <PartyDetail
              party={agreementData.wholeAgreement.fourthParty}
              agreementData={agreementData}
            />
          </div>
        )}
      {agreementData.wholeAgreement.twentythParty && (agreementData.wholeAgreement.fifthParty.name ||
        agreementData.wholeAgreement.fifthParty.indi_name) && (
          <div>
            <div className={styles.title}>fifth party</div>
            {/* <AddressRenderer {...secondParty} /> */}
            <PartyDetail
              party={agreementData.wholeAgreement.fifthParty}
              agreementData={agreementData}
            />
          </div>
        )}
      {agreementData.wholeAgreement.twentythParty && (agreementData.wholeAgreement.sixthParty?.indi_name ||
        agreementData.wholeAgreement.sixthParty.name) && (
          <div>
            <div className={styles.title}>sixth party</div>
            {/* <AddressRenderer {...secondParty} /> */}
            <PartyDetail
              party={agreementData.wholeAgreement.sixthParty}
              agreementData={agreementData}
            />
          </div>
        )}
      {agreementData.wholeAgreement.twentythParty && (agreementData.wholeAgreement.seventhParty?.indi_name ||
        agreementData.wholeAgreement.seventhParty.name) && (
          <div>
            <div className={styles.title}>seventh party</div>
            {/* <AddressRenderer {...secondParty} /> */}
            <PartyDetail
              party={agreementData.wholeAgreement.seventhParty}
              agreementData={agreementData}
            />
          </div>
        )}
      {agreementData.wholeAgreement.twentythParty && (agreementData.wholeAgreement.eighthParty?.indi_name ||
        agreementData.wholeAgreement.eighthParty.name) && (
          <div>
            <div className={styles.title}>eighth party</div>
            {/* <AddressRenderer {...secondParty} /> */}
            <PartyDetail
              party={agreementData.wholeAgreement.eighthParty}
              agreementData={agreementData}
            />
          </div>
        )}
      {agreementData.wholeAgreement.twentythParty && (agreementData.wholeAgreement.ninthParty?.indi_name ||
        agreementData.wholeAgreement.ninthParty.name) && (
          <div>
            <div className={styles.title}>ninth party</div>
            {/* <AddressRenderer {...secondParty} /> */}
            <PartyDetail
              party={agreementData.wholeAgreement.ninthParty}
              agreementData={agreementData}
            />
          </div>
        )}
      {agreementData.wholeAgreement.twentythParty && (agreementData.wholeAgreement.tenthParty?.indi_name ||
        agreementData.wholeAgreement.tenthParty.name) && (
          <div>
            <div className={styles.title}>tenth party</div>
            {/* <AddressRenderer {...secondParty} /> */}
            <PartyDetail
              party={agreementData.wholeAgreement.tenthParty}
              agreementData={agreementData}
            />
          </div>
        )}
      {agreementData.wholeAgreement.twentythParty && (agreementData.wholeAgreement.eleventhParty?.indi_name ||
        agreementData.wholeAgreement.eleventhParty.name) && (
          <div>
            <div className={styles.title}>eleventh party</div>
            {/* <AddressRenderer {...secondParty} /> */}
            <PartyDetail
              party={agreementData.wholeAgreement.eleventhParty}
              agreementData={agreementData}
            />
          </div>
        )}
      {agreementData.wholeAgreement.twentythParty && (agreementData.wholeAgreement.twelthParty?.indi_name ||
        agreementData.wholeAgreement.twelthParty.name) && (
          <div>
            <div className={styles.title}>twelth party</div>
            {/* <AddressRenderer {...secondParty} /> */}
            <PartyDetail
              party={agreementData.wholeAgreement.twelthParty}
              agreementData={agreementData}
            />
          </div>
        )}
      {agreementData.wholeAgreement.twentythParty && (agreementData.wholeAgreement.thirteenthParty?.indi_name ||
        agreementData.wholeAgreement.thirteenthParty.name) && (
          <div>
            <div className={styles.title}>thirteenth party</div>
            {/* <AddressRenderer {...secondParty} /> */}
            <PartyDetail
              party={agreementData.wholeAgreement.thirteenthParty}
              agreementData={agreementData}
            />
          </div>
        )}
      {agreementData.wholeAgreement.twentythParty && (agreementData.wholeAgreement.fourteenthParty?.indi_name ||
        agreementData.wholeAgreement.fourteenthParty.name) && (
          <div>
            <div className={styles.title}>fourteenth party</div>
            {/* <AddressRenderer {...secondParty} /> */}
            <PartyDetail
              party={agreementData.wholeAgreement.fourteenthParty}
              agreementData={agreementData}
            />
          </div>
        )}
      {agreementData.wholeAgreement.twentythParty && (agreementData.wholeAgreement.fifteenthParty?.indi_name ||
        agreementData.wholeAgreement.fifteenthParty.name) && (
          <div>
            <div className={styles.title}>fifteenth party</div>
            {/* <AddressRenderer {...secondParty} /> */}
            <PartyDetail
              party={agreementData.wholeAgreement.fifteenthParty}
              agreementData={agreementData}
            />
          </div>
        )}
      {agreementData.wholeAgreement.twentythParty && (agreementData.wholeAgreement.sixteenthParty?.indi_name ||
        agreementData.wholeAgreement.sixteenthParty.name) && (
          <div>
            <div className={styles.title}>sixteenth party</div>
            {/* <AddressRenderer {...secondParty} /> */}
            <PartyDetail
              party={agreementData.wholeAgreement.sixteenthParty}
              agreementData={agreementData}
            />
          </div>
        )}
      {agreementData.wholeAgreement.twentythParty && (agreementData.wholeAgreement.seventeenthParty?.indi_name ||
        agreementData.wholeAgreement.seventeenthParty.name) && (
          <div>
            <div className={styles.title}>seventeenth party</div>
            {/* <AddressRenderer {...secondParty} /> */}
            <PartyDetail
              party={agreementData.wholeAgreement.seventeenthParty}
              agreementData={agreementData}
            />
          </div>
        )}
      {agreementData.wholeAgreement.twentythParty && (agreementData.wholeAgreement.eighteenthParty?.indi_name ||
        agreementData.wholeAgreement.eighteenthParty.name) && (
          <div>
            <div className={styles.title}>eighteenth party</div>
            {/* <AddressRenderer {...secondParty} /> */}
            <PartyDetail
              party={agreementData.wholeAgreement.eighteenthParty}
              agreementData={agreementData}
            />
          </div>
        )}
      {agreementData.wholeAgreement.twentythParty && (agreementData.wholeAgreement.nineteenthParty?.indi_name ||
        agreementData.wholeAgreement.nineteenthParty.name) && (
          <div>
            <div className={styles.title}>nineteenth party</div>
            {/* <AddressRenderer {...secondParty} /> */}
            <PartyDetail
              party={agreementData.wholeAgreement.nineteenthParty}
              agreementData={agreementData}
            />
          </div>
        )}
      {agreementData.wholeAgreement.twentythParty && (agreementData.wholeAgreement.twentythParty?.indi_name ||
        agreementData.wholeAgreement.twentythParty.name) && (
          <div>
            <div className={styles.title}>twentyth party</div>
            {/* <AddressRenderer {...secondParty} /> */}
            <PartyDetail
              party={agreementData.wholeAgreement.twentythParty}
              agreementData={agreementData}
            />
          </div>
        )}
      {/* <div className="flex flex-row justify-end">
      // initial edit delete button
      </div> */}

      {!agreementData.data.json.isLegacy && (
        <div className="mb-4">
          <h1 className="text-xl font-500">user roles</h1>
          <div className="grid grid-cols-4 gap-4 ">
            <div>
              <h1>initiator : </h1>
              {initiators &&
                Object.keys(initiators).map((key) => {
                  const initiator = initiators[key];
                  return (
                    <div key={initiator.id}>
                      <h2
                        className={
                          checkUserCompletionInFlow("3", initiator.id)
                            ? `text-green-500`
                            : `text-yellow-500`
                        }
                      >
                        {initiator.name}
                      </h2>
                    </div>
                  );
                })}
            </div>

            <div>
              <h1>editor: </h1>
              {editors &&
                Object.keys(editors).map((key) => {
                  const editor = editors[key];
                  return (
                    <div key={editor.id}>
                      <h2
                        className={
                          checkUserCompletionInFlow("4", editor.id)
                            ? `text-green-500`
                            : `text-yellow-500`
                        }
                      >
                        {editor.name}
                      </h2>
                    </div>
                  );
                })}
            </div>

            <div>
              <h1>reviewer:</h1>
              {reviewers &&
                Object.keys(reviewers).map((key) => {
                  const reviewer = reviewers[key];
                  return (
                    <div key={reviewer.id}>
                      <h2
                        className={
                          checkUserCompletionInFlow("5", reviewer.id)
                            ? `text-green-500`
                            : `text-yellow-500`
                        }
                      >
                        {reviewer.name}
                      </h2>
                    </div>
                  );
                })}
            </div>
            <div>
              <h1>approver : </h1>
              {approvers &&
                Object.keys(approvers).map((key) => {
                  const approver = approvers[key];
                  return (
                    <div key={approver.id}>
                      <h2
                        className={
                          checkUserCompletionInFlow("6", approver.id)
                            ? `text-green-500`
                            : `text-yellow-500`
                        }
                      >
                        {approver.name}
                      </h2>
                    </div>
                  );
                })}
            </div>

            <div>
              <h1>sign-auth :</h1>
              {signAuths &&
                Object.keys(signAuths).map((key) => {
                  const signAuth = signAuths[key];
                  return (
                    <div key={signAuth.id}>
                      <h2
                        className={
                          checkUserCompletionInFlow("7", signAuth.id)
                            ? `text-green-500`
                            : `text-yellow-500`
                        }
                      >
                        {signAuth.name}
                      </h2>
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      )}
      <Modal
        overflow="inside"
        closeOnClickOutside={false}
        size="calc(30vw)"
        opened={open}
        onClose={() => setOpen(!open)}
        title="Delete Agreement"
      >
        <div className="my-2">
          <h4 className="mb-4 text-center">Are you sure you want to delete this agreement?</h4>
          <div className="flex flex-row justify-between">
            <Button
              style={{
                backgroundColor: colors.primary,
                borderRadius: "0.5rem",
                color: "#F5F5F5",
                // width: '180px',
              }}
              onClick={() => setOpen(false)}
            >
              Cancel
            </Button>
            <Button
              disabled={deleteLoading}
              className="ml-4"
              style={{
                backgroundColor: deleteLoading ? "#ffcccc" : "#ff4d4d",
                borderRadius: "0.5rem",
                color: "#F5F5F5",
                // width: '180px',
              }}
              onClick={() => deleteHandler()}
            >
              {deleteLoading ? (
                <BeatLoader size={10} color={colors.rejected} />
              ) : (
                "Delete"
              )}
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default AgreementDetail;
