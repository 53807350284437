/** @format */

import React, { useEffect, useState } from "react";
import { Button, Modal, TextInput, Text, Skeleton } from "@mantine/core";
import { showNotification } from "@mantine/notifications";

import styles from "../../components/TemplateManager/TemplateRow.module.css";
import { apiWrapWithErrorWithData } from "../../utilities/apiHelpers";
import {
  getAllClauseLibrary,
  createClauseLibrary,
} from "../../utilities/apis/clause";
import TemplateRow from "../../components/TemplateManager/TemplateRow";

function Clause() {
  const [clauseModalConfig, setClauseModalConfig] = useState({
    open: false,
    title: "",
    buttonLoading: false,
  });
  const [config, setConfig] = useState({
    loading: false,
    clauses: [],
  });

  const getAllLibrary = async () => {
    setConfig((pre) => ({ ...pre, loading: true }));
    const response = await apiWrapWithErrorWithData(getAllClauseLibrary());
    setConfig((pre) => ({
      ...pre,
      clauses: response.clauseLibrary,
      loading: false,
    }));
  };

  useEffect(() => {
    getAllLibrary();
  }, []);

  const createClause = async () => {
    if (!clauseModalConfig.title) {
      showNotification({
        color: "red",
        title: "Clause Library",
        message: "Please enter the folder name",
      });

      return;
    }

    setClauseModalConfig((preState) => ({ ...preState, buttonLoading: true }));
    const response = await apiWrapWithErrorWithData(
      createClauseLibrary({ title: clauseModalConfig.title })
    );

    if (response.success) {
      getAllLibrary();
      showNotification({
        color: "green",
        title: "Clause Library",
        message: "Folder is created",
      });
    } else {
      showNotification({
        color: "red",
        title: "Clause Library",
        message: "Unable to create Folder",
      });
    }
    setClauseModalConfig((preState) => ({
      ...preState,
      buttonLoading: false,
      open: false,
    }));
  };

  const showEditButton = () => {};

  const showViewButton = () => {};

  const viewHandler = () => {};

  return (
    <>
      <Modal
        opened={clauseModalConfig.open}
        onClose={() =>
          setClauseModalConfig((preState) => ({
            ...preState,
            open: false,
          }))
        }
      >
        <TextInput
          label="folder name"
          onChange={(e) =>
            setClauseModalConfig((pre) => ({ ...pre, title: e.target.value }))
          }
          placeholder="enter folder name"
        />
        <div className="flex justify-end my-4">
          <Button
            disabled={clauseModalConfig.buttonLoading}
            onClick={createClause}
          >
            create
          </Button>
        </div>
      </Modal>
      <div className="flex flex-col pt-8 pb-14 pl-6 pr-16">
        <div className={styles.title}>{`clause library`}</div>
        <div className="flex flex-row justify-between mt-6">
          <div className={`${styles.text} mt-1`}></div>
        </div>
        <div className="flex justify-between my-4 items-center">
          <div></div>
          <Button
            onClick={() =>
              setClauseModalConfig((preState) => ({
                ...preState,
                open: true,
              }))
            }
            style={{
              backgroundColor: "#46BDE1",
            }}
          >
            add folder
          </Button>
        </div>
        <div className="mt-16">
          {config.loading ? (
            <div className="flex flex-col">
              {Array.from({ length: 5 }, (_) => (
                <Skeleton height={40} className="mb-11" />
              ))}
            </div>
          ) : (
            config.clauses.map((library) => (
              <TemplateRow
                editButton={showEditButton}
                viewButton={false}
                module={"clause"}
                onViewClick={() => {
                  viewHandler(library);
                }}
                {...library}
              />
            ))
          )}

          {config.clauses?.length === 0 && (
            <div className="my-4 flex justify-center">
              <Text>No ClauseLibraries</Text>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default Clause;
