/**
 * @format
 */
import { getCookiesSession } from "../cookiesSession";
import { format, parseISO } from "date-fns";
import { showNotification } from '@mantine/notifications';
import { PDFDocument } from 'pdf-lib';
export const assetsPrefix = "/assets";

export const loadingStates = {
  NO_ACTIVE_REQUEST: "NO_ACTIVE_REQUEST",
  LOADING: "LOADING",
  FINISHED: "FINISHED",
  FAILED: "FAILED",
  SUCCEEDED: "SUCCEEDED",
};

export const getUserDataFromCookies = () => {
  let data = null;
  try {
    data = JSON.parse(getCookiesSession("trueCounselUserData"));
  } catch (e) {
    console.log(e);
  }
  return data;
};


export const mergePDFs = async (file1, file2, outputFileName) => {
  try {
    const mergedPdf = await PDFDocument.create();
    console.log("came here to merge files");
    // Load each PDF file and add its pages to the merged PDF
    for (const file of [file1, file2]) {
      const pdfBytes = await file.arrayBuffer(); // Read the file as an ArrayBuffer
      const pdfDoc = await PDFDocument.load(pdfBytes);
      const copiedPages = await mergedPdf.copyPages(pdfDoc, pdfDoc.getPageIndices());
      copiedPages.forEach((page) => {
        mergedPdf.addPage(page);
      });
    }

    // Save the merged PDF as a Blob
    const pdfBytes = await mergedPdf.save();
    const blob = new Blob([pdfBytes], { type: 'application/pdf' });
      console.log("pdf files mmerged");
    // Return an object with the Blob and the specified filename
    return { blob, filename: outputFileName };
  } catch (err) {
    console.error('PDF merge error: ', err);
    return null;
  }
};


export const cleanFileObj = (file) => {
  let fileName = file.fileData ? file.fileData.name : null;
  if (!fileName && file.name) {
    fileName = file.name;
  }
  if (!fileName && file.filename) {
    fileName = file.filename;
  }
  if (!fileName && file.fileName) {
    fileName = file.fileName;
  }
  if (!fileName && file.destination) {
    fileName = getFileName(file.destination);
  }
  if (!fileName || !fileName.length) {
    fileName = "Un-Named File";
  }
  return {
    fileName,
    destination: file.destination || null,
  };
};

export function nl2br(str, is_xhtml) {
  if (typeof str === "undefined" || str === null) {
    return "";
  }
  const breakTag =
    is_xhtml || typeof is_xhtml === "undefined" ? "<br />" : "<br>";
  return `${str}`.replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, `$1${breakTag}$2`);
}

export const getFileName = (url) => {
  const decoded = decodeURI(decodeURI(url));
  const splits = decoded.split("-tc-");
  if (splits.length === 2) {
    return splits[splits.length - 1]
      .split("/")
      .pop()
      .split("#")[0]
      .split("?")[0];
  }
  return null;
};

export const noticeRequestStatuses = {
  APPROVED: "APPROVED",
  REJECTED: "REJECTED",
  PENDING: "PENDING",
  ALL: "",
};

export const noticeStatuses = {
  ALL: "",
  ["INITIAL DRAFT"]: "INITIAL DRAFT",
  DRAFT: "DRAFT",
  SENT: "SENT",
};

export const validateEmail = (email) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

// export const validateMobile = (phone) => {
//   return !isNaN(parseInt(phone, 10))
// }

export const validateMobile = (phone) => {
  if (isNaN(phone)) {
    return false;
  }
  return !isNaN(parseInt(phone, 10));
};

export const validateAddress = (addr) => {
  const erroredKeys = [];
  if (!addr.type.length) {
    erroredKeys.push("type");
  }
  if (addr.name.length < 3) {
    erroredKeys.push("name");
  }
  if (addr.email.length < 3) {
    erroredKeys.push("email");
  }
  if (!validateEmail(addr.email)) {
    erroredKeys.push("email");
  }
  if (addr.type === "Individual") {
    if (addr.phone.length < 10) {
      erroredKeys.push("phone");
    }
    if (isNaN(parseInt(addr.phone, 10))) {
      erroredKeys.push("phone");
    }
    if (addr.sonOf.length < 3) {
      erroredKeys.push("sonOf");
    }
    if (addr.residenceOf.length < 5) {
      erroredKeys.push("residenceOf");
    }
  }
  if (addr.type === "Entity") {
    if (addr.registeredOfficeAddress.length < 5) {
      erroredKeys.push("registeredOfficeAddress");
    }
    if (addr.corporateOfficeAddress.length < 5) {
      erroredKeys.push("corporateOfficeAddress");
    }
    if (addr.companyType.length < 3) {
      erroredKeys.push("companyType");
    }
  }
  return { result: erroredKeys.length === 0, erroredKeys };
};

const cleanAddrObject = (addr, type = "truncate") => {
  const newAddr = { ...addr };
  if (newAddr.type === "Individual") {
    if (type === "remove") {
      delete newAddr.registeredOfficeAddress;C
      delete newAddr.corporateOfficeAddress;
      delete newAddr.companyType;
    }
    if (type === "truncate") {
      newAddr.corporateOfficeAddress = "";
      newAddr.companyType = "";
      newAddr.registeredOfficeAddress = "";
    }
  }
  if (newAddr.type === "Entity") {
    if (type === "remove") {
      delete newAddr.phone;
      delete newAddr.sonOf;
      delete newAddr.residenceOf;
    }
    if (type === "truncate") {
      newAddr.phone = "";
      newAddr.sonOf = "";
      newAddr.residenceOf = "";
    }
  }
  return newAddr;
};

export const formatTime = (time) => {
  let tempDate = time;
  if (!tempDate) {
    return "-";
  }
  if (!(typeof tempDate.getMonth === "function")) {
    try {
      tempDate = parseISO(tempDate);
    } catch (e) {
      console.log(e);
      tempDate = null;
    }
  }
  if (!tempDate) {
    return "-";
  }
  return format(tempDate, "hh:mm aa");
};

export const formatDate = (date) => {
  let tempDate = date;
  if (!tempDate) {
    return "-";
  }
  if (!(typeof tempDate.getMonth === "function")) {
    try {
      tempDate = parseISO(tempDate);
    } catch (e) {
      console.log(e);
      tempDate = null;
    }
  }
  if (!tempDate) {
    return "-";
  }
  return format(tempDate, "dd MMM yyyy");
};

export const taskStatuses = {
  CREATED: "CREATED",
  COMPLETED: "COMPLETED",
  ARCHIVED: "ARCHIVED",
};

export const isDateObj = (value) => {
  if (!value) {
    return false;
  }
  return typeof value.getMonth === "function";
};

export const existsAndLength = (value) => {
  return value?.length > 3;
};

export const transformFirebaseNotifs = (notifs) => {
  const keys = Object.keys(notifs);
  const arr = [];
  keys.forEach((notifKey) => {
    arr.push({ ...notifs[notifKey], key: notifKey });
  });
  return arr;
};

export const getUploadedFileFromObj = (file) => {
  return {
    ...file,
    uploadedComplete: true,
    uploadStarted: true,
  };
};

export const getValueForInput = (incoming) => {
  let value;
  if (incoming.target) {
    if (incoming.target.value !== undefined) {
      value = incoming.target.value;
    }
  } else {
    value = incoming;
  }
  return value;
};

export const addressDefaultData = {
  type: "Individual",
  name: "",
  email: "",
  phone: "",
  sonOf: "",
  residenceOf: "",
  companyType: "Company",
  registeredOfficeAddress: "",
  corporateOfficeAddress: "",
};

export const isLoading = (value) => value === loadingStates.LOADING;

export const arrayToTextList = (arr) => arr.join(", ");

export const handleFromEmail = (obj) => {
  if (Array.isArray(obj)) {
    return obj;
  }
  return [obj?.email || ""];
};

export const randomColorPicker = (length) => {
  // colors taken from https://colorhunt.co/palettes/pastel
  const colors = [
    "#829460",
    "#AD7BE9",
    "#EDC6B1",
    "#7C96AB",
    "#05BFDB",
    "#EB455F",
    "#645CBB",
    "#867070",
    "#804674",
    "#FF8787",
    "#EA8FEA",
    "#805674",
    "#8FBDD3",
    "#B97A95",
    "#829463",
    "#EDC6B5",
    "#7C96A8",
    "#EB4542",
  ];
  const randomColors = [];

  while (true) {
    const randomColor = colors[Math.floor(Math.random() * colors.length)];
    if (!randomColors.includes(randomColor)) {
      randomColors.push(randomColor);
      if (randomColors.length === length) break;
    }
  }
  return randomColors;
};

export const randomColorPickerForLocation = (length) => {
  // colors taken from https://colorhunt.co/palettes/pastel
  const colors = [
    "#829460",
    "#AD7BE9",
    "#EDC6B1",
    "#7C96AB",
    "#05BFDB",
    "#EB455F",
    "#645CBB",
    "#867070",
    "#804674",
    "#FF8787",
    "#EA8FEA",
    "#804654",
    "#8FBDD3",
    "#B97A95",
    "#829463",
    "#EDC6B5",
    "#7C96A8",
    "#EB4542",
    "#829450",
    "#AD7C49",
    "#EB444F",
    "#ECD6B1",
    "#7B56AB",
    "#77BFDB",
    "#EB4666",
    "#546CBB",
    "#867460",
    "#893674",
    "#DD8787",
    "#FEAFEA",
    "#804784",
    "#8FBEE3",
    "#B97A99",
    "#833463",
    "#EAA6B5",
    "#7E76A8",
    "#EB4552",
    "#899450",
    "#AD7C09",
  ];
  const randomColors = [];

  while (true) {
    const randomColor = colors[Math.floor(Math.random() * colors.length)];
    randomColors.push(randomColor);
    if (randomColors.length === length) break;
  }
  return randomColors;
};

const chartData = {
  labels: ["Red", "Blue", "Yellow", "Green", "Purple", "Orange"],
  datasets: [
    {
      label: "# of Votes",
      data: [12, 19, 3, 5, 2, 3],
      backgroundColor: [
        "#3a36db",
        "#1f6fbd",
        "#03a89e",
        "#8189a9",
        "#c079af",
        "#ff69b4",
      ],
      borderColor: [
        "#3a36db",
        "#1f6fbd",
        "#03a89e",
        "#8189a9",
        "#c079af",
        "#ff69b4",
      ],
      borderWidth: 1,
    },
  ],
};

export const customChartData = (prop) => (wiseData) => {
  const data = structuredClone(chartData);
  data.labels = wiseData.map((item) => `${item[prop]}/${item["_count"].id}`);
  data.datasets[0].data = wiseData.map((item) => item["_count"].id);
  data.datasets[0].backgroundColor = [...randomColorPicker(wiseData.length)];
  data.datasets[0].borderColor = [...data.datasets[0].backgroundColor];
  return { ...data };
};

export const customChartDataForLocation = (prop) => (wiseData) => {
  const data = structuredClone(chartData);
  data.labels = wiseData.map((item) => `${item[prop]}/${item["_count"].id}`);
  data.datasets[0].data = wiseData.map((item) => item["_count"].id);
  data.datasets[0].backgroundColor = [...randomColorPickerForLocation(wiseData.length)];
  data.datasets[0].borderColor = [...data.datasets[0].backgroundColor];
  return { ...data };
};

export const totalCountChart = (data) =>
  data.reduce((sum, { _count }) => sum + _count.id, 0);

export const camelCaseToTitle = (text) => {
  const result = text.replace(/([A-Z])/g, " $1");
  return result.charAt(0).toUpperCase() + result.slice(1);
};

export const csvDownload = ({ headers, data, name }) => {
  let csvContent = `data:text/csv;charset=utf-8,`;
  csvContent += headers.join(",") + "\n";

  data.forEach((item) => {
    headers.forEach((header) => {
      csvContent += `${item[header]},`;
    });
    csvContent += "\n";
  });

  const encodedUri = encodeURI(csvContent);
  const link = document.createElement("a");
  link.setAttribute("href", encodedUri);
  link.setAttribute("download", `${name}.csv`);
  document.body.appendChild(link);
  link.click();
};

export const copyToClipboard = async (textToCopy) => {
  try {
    await navigator.clipboard.writeText(textToCopy);
    console.log('Text copied to clipboard');
    showNotification({
      color: "green",
      message: "Text copied to clipboard",
    });
  } catch (error) {
    console.error('Failed to copy text:', error);
    showNotification({
      color: "red",
      message: "Unable to copy the text into clipboard",
    });
  }
};

